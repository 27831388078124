exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-prismic-page-url-tsx": () => import("./../../../src/pages/{PrismicPage.url}.tsx" /* webpackChunkName: "component---src-pages-prismic-page-url-tsx" */),
  "component---src-templates-article-article-page-tsx": () => import("./../../../src/templates/article/ArticlePage.tsx" /* webpackChunkName: "component---src-templates-article-article-page-tsx" */),
  "component---src-templates-category-category-page-tsx": () => import("./../../../src/templates/category/CategoryPage.tsx" /* webpackChunkName: "component---src-templates-category-category-page-tsx" */),
  "component---src-templates-map-map-page-tsx": () => import("./../../../src/templates/map/MapPage.tsx" /* webpackChunkName: "component---src-templates-map-map-page-tsx" */),
  "component---src-templates-newsletter-newsletter-issue-page-tsx": () => import("./../../../src/templates/newsletter/NewsletterIssuePage.tsx" /* webpackChunkName: "component---src-templates-newsletter-newsletter-issue-page-tsx" */)
}

